import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BaseFrameState } from '@utils/hooks/useBaseFrame';
import { Button, Flex, LabelText } from '@components';
import { useBFContext } from '@context';
import { FRAME_COLORS, getMixMaterialSwatchesOptions } from '@constants';
import { useTranslation } from '@utils/index';
import styles from './Material.module.scss';

type MaterialProps = {
	callback: (material: string, frameColor: string) => void;
	vtoState?: BaseFrameState;
	selectedMaterial: string;
};

const getTranslatedTexts = translator => {
	return {
		acetate: translator('acetate'),
		material: translator('material'),
	};
};

const Material = ({ callback, vtoState, selectedMaterial }: MaterialProps) => {
	const { frameColor } = useBFContext();
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isColorRefresh = useFeatureIsOn('is-color-refresh-2');
	const MIX_MATERIAL_SWATCHES_OPTIONS = getMixMaterialSwatchesOptions(locale, isColorRefresh);

	const materialMappings = MIX_MATERIAL_SWATCHES_OPTIONS.reduce((acc, option) => {
		if (option.metal) {
			acc[option.acetate] = ['Acetate', option.metal];
			acc[option.metal] = ['Acetate', option.metal];
		} else {
			acc[option.acetate] = ['Acetate', FRAME_COLORS.MIX_CRYSTAL_SILVER];
		}

		return acc;
	}, {});

	const frameColorOption = vtoState ? vtoState.frame : frameColor;
	const materials = materialMappings[frameColorOption];

	return (
		<>
			<LabelText>{translations.material}</LabelText>
			<Flex gap={3}>
				{materials.map((material, index) => {
					const label =
						material === 'Acetate'
							? translations.acetate
							: MIX_MATERIAL_SWATCHES_OPTIONS.find(option => option.metal === material)?.label;
					return (
						<Button
							key={index}
							label={label}
							size='small'
							color='white'
							onClick={() => {
								// Added this override since Green Metal has no equivalent in Acetates.
								const color =
									material === 'Acetate' && frameColor === FRAME_COLORS.MIX_GREEN_GOLD
										? FRAME_COLORS.CRYSTAL_CLEAR
										: frameColor;

								callback(material, color);
							}}
							removeEffects
							extraClasses={selectedMaterial === material ? styles.selectButton : styles.materialButton}
							dataTags={{ 'data-base-frame-material': label }}
						/>
					);
				})}
			</Flex>
		</>
	);
};

export default Material;
