import React from 'react';
import { Body, Detail, Title } from '@components/typography';
import { NormalizedProduct, PreviousBaseFrame } from '@ts/index';
import { BaseVariantPriceDictionary } from '@utils/constants/base-skus';
import { CUSTOMER_JOURNEYS, LENS_COLORS, NEW_BASE_FRAME_NAMES } from '@constants';
import { Divider } from '@components/common';
import variables from '@styles/export.module.scss';
import styles from './ReApplyLenses.module.scss';
import ReApplyLensesCard from './ReApplyLensesCard';
import { useTranslation } from '@utils/index';

type ReApplyLensesProps = {
	handle: string;
	frame: NEW_BASE_FRAME_NAMES;
	frameColor: string;
	previousBaseFrames: PreviousBaseFrame[];
	variantPrices?: BaseVariantPriceDictionary;
	journey: CUSTOMER_JOURNEYS;
	lensColor?: LENS_COLORS;
	readerRx?: string;
	product: NormalizedProduct;
};

const getTranslatedTexts = translator => {
	return {
		buildFromPreviousOrder: translator('build-from-previous-order'),
		saveTimeFromPrevious: (frame: string, frameColor: string) => translator('save-time-from-previous', { frame, frameColor }),
		or: translator('or-lowercase'),
	};
};

const ReApplyLenses = ({ frame, frameColor, previousBaseFrames, ...rest }: ReApplyLensesProps) => {
	const { translator } = useTranslation();
	if (!previousBaseFrames?.length) return null;
	const translations = getTranslatedTexts(translator);

	const cards = previousBaseFrames.slice(0, 2);
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<Title>{translations.buildFromPreviousOrder}</Title>
				<Body>{translations.saveTimeFromPrevious(frame, frameColor)}</Body>
			</div>
			<div className={styles.content}>
				{cards.map((previousBaseFrame, index) => (
					<ReApplyLensesCard
						key={`reapply-card-${index}`}
						frameColor={frameColor}
						frame={frame}
						previousBaseFrame={previousBaseFrame}
						{...rest}
					/>
				))}
			</div>

			<div className={styles.divider}>
				<Divider color={variables.gray3} alignment='horizontal' />
				<Detail>{translations.or}</Detail>
				<Divider color={variables.gray3} alignment='horizontal' />
			</div>
		</div>
	);
};

export default ReApplyLenses;
