import Parser from 'html-react-parser';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { VariantControl } from '@ts/product';
import {
	About,
	BaseFrameShapeColor,
	Button,
	Flex,
	Material,
	Paragraph,
	Spacer,
	SwatchController,
	SwatchOption,
} from '@components';
import { useIsMobile, useTranslation } from '@utils/hooks';
import {
	BASE_FRAME_NAMES,
	COLOR_REFRESH_BASE_FRAMES,
	FRAME_COLORS,
	LIMITED_COLORS,
	LIMITED_METAL_COLORS,
	MEDIUM_WIDTH,
	METAL_BASE_FRAMES,
	NEW_METAL_FRAME_COLORS,
	PRODUCT_TYPES,
} from '@constants';
import styles from '../ProductViewCTA/ProductViewCTA.module.scss';

type ProductViewDescriptionProps = {
	primaryController: VariantControl & { buttonProps?: Parameters<typeof Button>[0] };
	aboutProps: Parameters<typeof About>[0];
	name: string;
	secondaryController?: VariantControl;
	updateFrameShape?: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	variantsAvailable?: Set<string>;
	colorController?: VariantControl;
	productType?: string;
	selectedMaterial?: string;
	handleMaterialChange?: (material: string, frameColor: string) => void;
	frameShape?: (typeof BASE_FRAME_NAMES)[number];
};

const getTranslatedTexts = translator => {
	return {
		new: translator('new'),
		classic: translator('classic'),
		limitedEdition: translator('limited-edition'),
	};
};

function ProductViewDescription({
	primaryController,
	aboutProps,
	name,
	secondaryController,
	updateFrameShape,
	variantsAvailable,
	colorController,
	productType,
	selectedMaterial,
	handleMaterialChange,
	frameShape,
}: ProductViewDescriptionProps) {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const isHideNewProducts = useFeatureIsOn('is-hide-new-products');
	const isColorRefresh = useFeatureIsOn('is-color-refresh-2');
	const tag: SwatchOption['tag'] = { label: translations.new, type: 'success' };
	const baseFrameNames = name.split(' ')[1];
	const isMetalBF = selectedMaterial.includes('Metal');
	const newColors = isMetalBF ? (isColorRefresh ? LIMITED_METAL_COLORS : []) : LIMITED_COLORS;
	const filteredOptions = primaryController?.options
		?.filter(option => !newColors.includes(option))
		.map(option => (NEW_METAL_FRAME_COLORS.includes(option) ? { name: option, tag } : { name: option }));

	if (primaryController.controllerType === 'accessory')
		return (
			<>
				<Spacer size={'0.8rem'} />
				<Paragraph>{aboutProps?.description}</Paragraph>
				<Spacer size={'1.6rem'} />
			</>
		);
	const shouldShowClassic =
		COLOR_REFRESH_BASE_FRAMES.includes(baseFrameNames as (typeof COLOR_REFRESH_BASE_FRAMES)[number]) && !isHideNewProducts;

	return (
		<>
			{primaryController.controllerType === 'shape' ? (
				<>
					{aboutProps?.descriptionHtml ? (
						<>
							<Spacer size={'0.8rem'} />
							<Paragraph className={styles.description}>{Parser(aboutProps?.descriptionHtml)}</Paragraph>
							<Spacer size={'1.6rem'} />
						</>
					) : aboutProps?.description ? (
						<>
							<Spacer size={'0.8rem'} />
							<Paragraph>{aboutProps?.description}</Paragraph>
							<Spacer size={'1.6rem'} />
						</>
					) : null}
					{!isMobile && (
						<BaseFrameShapeColor
							callback={updateFrameShape}
							primaryController={primaryController}
							variantsAvailable={variantsAvailable}
							colorController={colorController}
							productType={productType}
							frameShape={frameShape}
						/>
					)}
				</>
			) : (
				<Flex column gap={3} style={{ marginBottom: '1.6rem' }}>
					{!isMobile && (
						<>
							<Paragraph>{aboutProps?.description}</Paragraph>
						</>
					)}
					{primaryController.variantImages && (
						<>
							<SwatchController
								alignment='left'
								options={filteredOptions}
								callback={primaryController?.callback}
								selected={primaryController?.selected}
								type={
									primaryController?.controllerType as Exclude<typeof primaryController.controllerType, 'shape'>
								}
								title={primaryController?.title}
								label={shouldShowClassic && translations.classic}
								name={name}
								excludeColorLabel={
									!filteredOptions.find(option => option.name === primaryController.selected)?.name
								}
							/>
							{filteredOptions?.length !== primaryController?.options.length && (
								<SwatchController
									alignment='left'
									options={newColors.map(color => ({ name: color, tag }))}
									callback={primaryController?.callback}
									selected={primaryController?.selected}
									type={
										primaryController?.controllerType as Exclude<
											typeof primaryController.controllerType,
											'shape'
										>
									}
									title={primaryController?.title}
									label={translations.limitedEdition}
									name={name}
									excludeColorLabel={!newColors.includes(primaryController.selected as FRAME_COLORS)}
								/>
							)}
						</>
					)}
					{productType.includes(PRODUCT_TYPES.BASE_FRAME_SR) &&
						METAL_BASE_FRAMES.includes(baseFrameNames as (typeof METAL_BASE_FRAMES)[number]) && (
							<Material callback={handleMaterialChange} selectedMaterial={selectedMaterial} />
						)}
					{secondaryController && (
						<SwatchController
							alignment='left'
							options={secondaryController.options}
							callback={secondaryController.callback}
							selected={secondaryController.selected}
							type={secondaryController.controllerType as Exclude<typeof primaryController.controllerType, 'shape'>}
							title={secondaryController.title}
							name={name}
						/>
					)}
				</Flex>
			)}
		</>
	);
}

export default ProductViewDescription;
