import { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Heading, Img, Modal, SwatchController } from '@components';
import { COLOR_REFRESH_BASE_FRAMES, FRAME_COLORS, LIMITED_COLORS } from '@constants';
import { useTranslation } from '@utils/index';
import styles from './FrameColorOptions.module.scss';

const getTranslatedTexts = translator => {
	return {
		saveColor: translator('save-color'),
		editColor: translator('edit-color'),
		theTitle: (frame: string) => translator('the-frame', { frame }),
		baseFrameColor: translator('base-frame-color'),
	};
};

const FrameColorOptions = ({ title, options, callback, selected, variantImages, frameShape }) => {
	const [activeColor, setActiveColor] = useState(selected);
	const [isChangedActiveColor, setIsChangedActiveColor] = useState(false);
	const image = variantImages?.[activeColor]?.[0];
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const handleSwatchChange = useCallback(
		({ option, type }) => {
			if (type === 'frame') {
				setActiveColor(option);
				setIsChangedActiveColor(option !== selected);
			}
		},
		[setActiveColor, setIsChangedActiveColor, selected]
	);

	useEffect(() => {
		if (frameShape && !COLOR_REFRESH_BASE_FRAMES.includes(frameShape) && LIMITED_COLORS.includes(selected)) {
			setActiveColor(FRAME_COLORS.BLACK);
			setIsChangedActiveColor(false);
		}
	}, [frameShape, options]);

	const handleEditColor = useCallback(() => {
		callback(activeColor);
		setIsChangedActiveColor(false);
	}, [activeColor, callback]);

	const buttonLabel = isChangedActiveColor ? translations.saveColor : translations.editColor;
	const dataAttributes = isChangedActiveColor ? { 'data-save-color': activeColor } : { 'data-edit-color': true };

	return (
		<Modal>
			<Modal.Trigger asChild>
				<Button color='white'>{translations.editColor}</Button>
			</Modal.Trigger>
			<Modal.Content className={styles.content}>
				<Flex className={styles.cardContainer} column>
					<Flex justify='center' className={styles.cardImage}>
						<Img src={image?.url} title={title} sizes='(min-width: 76.8em) 24em, 10em' />
					</Flex>
					<Flex column center className={styles.cardSection}>
						<Heading tag='h5' className={styles.title}>
							{translations.theTitle(title)}
						</Heading>
						<SwatchController
							alignment='left'
							options={options}
							callback={handleSwatchChange}
							selected={activeColor}
							type='frame'
							title={translations.baseFrameColor}
							name={title}
							activateOnHover
							label={translations.baseFrameColor}
						/>
						<Modal.Close asChild>
							<Button
								label={buttonLabel}
								color='green'
								size='medium'
								fullWidth
								onClick={handleEditColor}
								style={{ padding: '0.5rem 1.6rem' }}
								{...dataAttributes}
							/>
						</Modal.Close>
					</Flex>
				</Flex>
			</Modal.Content>
		</Modal>
	);
};

export default FrameColorOptions;
