import { useRef } from 'react';
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { Root as PortalRoot } from '@radix-ui/react-portal';
import { slideFromPaddedBottom } from '@utils/motions';
import { Button, SwatchController } from '@components';
import { PRODUCT_TYPES } from '@constants';
import { VariantControl } from '@ts/product';
import { frameSwatchesProps } from '@ts/components';
import styles from './ProductViewCTA.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		baseFrameColor: translator('base-frame-color'),
		topFrameLabel: translator('top-frame-label'),
	};
};

type FloatingCTANewProps = {
	buttonProps: Parameters<typeof Button>[0] & {
		hasSubtotal?: boolean;
		isLoading?: boolean;
		availableForSale?: boolean;
		onClick?: (ref?: React.MutableRefObject<HTMLButtonElement | null>) => void;
	};
	name: string;
	primaryController?: VariantControl & { buttonProps?: Parameters<typeof Button>[0] };
	collectionController?: VariantControl & {
		frameSwatches?: frameSwatchesProps;
	};
	productType: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	showNewSticky?: boolean;
};

function FloatingCTANew({
	buttonProps,
	name,
	primaryController,
	collectionController,
	productType,
	showNewSticky,
}: FloatingCTANewProps) {
	const classes = cn(styles['cta'], buttonProps.extraClasses);
	const FloatingCTANew = useRef<HTMLButtonElement>(null);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const isBaseFrame = productType.includes(PRODUCT_TYPES.BASE_FRAME);
	const isTopFrame = productType.includes(PRODUCT_TYPES.TOP_FRAME) && !productType.includes(PRODUCT_TYPES.BUNDLE);
	const controller = isBaseFrame ? primaryController : collectionController;
	const filteredOptions = isBaseFrame ? primaryController?.options : collectionController.options;

	return (
		<PortalRoot {...(typeof document !== 'undefined' && { container: document.getElementById('__next') })}>
			<AnimatePresence>
				<m.div className={styles['stickyButton--New']} data-floating-cta={buttonProps.label} {...slideFromPaddedBottom}>
					{(isBaseFrame || isTopFrame) && (
						<SwatchController
							alignment='left'
							options={filteredOptions}
							callback={controller?.callback}
							selected={controller?.selected}
							type={controller?.controllerType as Exclude<typeof controller.controllerType, 'shape'>}
							title={controller?.title}
							label={isBaseFrame ? translations.baseFrameColor : translations.topFrameLabel}
							name={name}
							frameSwatches={!isBaseFrame ? collectionController?.frameSwatches : undefined}
							excludeColorLabel={isBaseFrame ? !filteredOptions.includes(primaryController.selected) : undefined}
							showNewSticky={showNewSticky}
						/>
					)}
					<Button
						extraClasses={classes}
						size={'medium'}
						color='green'
						fullWidth
						onClick={() => buttonProps.onClick(FloatingCTANew)}
						withChevron={buttonProps.withChevron}
						chevronDirection='right'
						withPrice
						showPriceWithLabel
						price={buttonProps.price}
						label={buttonProps.label}
						disabled={buttonProps.disabled || !buttonProps.availableForSale}
						ref={FloatingCTANew}
						{...buttonProps.dataTags}
					/>
				</m.div>
			</AnimatePresence>
		</PortalRoot>
	);
}

export default FloatingCTANew;
